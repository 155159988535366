const Constants = {
  // imageBaseUrl: "http://localhost:5000/uploads/",
  // imageBaseUrl: "http://54.157.240.144/uploads/",
  // imageBaseUrl: "/ima/uploads/",
  //original
  imageBaseUrl: 'https://api.idealign.in/uploads/'
  // imageBaseUrl: 'http://93.127.206.10/api',
};

export default Constants;
