import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Grid, Avatar, TextareaAutosize } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from 'ui-component/cards/BreadCrumbs';
import Constants from 'utils/constants';
import { editTeams } from 'store/teams/actions'; // Assuming you have an editTeams action

const EditTeams = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [memberName, setMemberName] = useState('');
  const [memberDesignation, setMemberDesignation] = useState('');
  const [description, setDescription] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [mainImageURL, setMainImageURL] = useState('');
  const [objectPositionValue, setObjectPositionValue] = useState(0);

  const { teamsList } = useSelector((state) => state?.teams); // Assuming teams are stored in Redux
  const team = teamsList?.find((item) => item._id === id);

  useEffect(() => {
    if (team) {
      setMemberName(team.memberName);
      setMemberDesignation(team.memberDesignation);
      setDescription(team.description);
      setObjectPositionValue(team.objectPositionValue);
      setMainImage(team.mainImage);
    }
  }, [team]);

  useEffect(() => {
    if (!mainImage) {
      setMainImageURL(null);
    } else {
      if (mainImage === team.mainImage) {
        setMainImageURL(`${Constants.imageBaseUrl}${team.mainImage}`);
      } else {
        setMainImageURL(URL.createObjectURL(mainImage));
      }
    }
  }, [mainImage, team]);

  const handleClickUpdate = async (e) => {
    e.preventDefault();
    const data = {
      memberName,
      mainImage,
      memberDesignation,
      description,
      objectPositionValue
    };
    const validationErrors = validateForm(data);
    if (Object.keys(validationErrors).length === 0) {
      let count = 0;
      const formData = new FormData();
      if (memberName !== team.memberName) {
        formData.append('memberName', memberName);
        count++;
      }
      if (memberDesignation !== team?.memberDesignation) {
        formData.append('memberDesignation', memberDesignation);
        count++;
      }
      if (mainImage !== team.mainImage) {
        formData.append('mainImage', mainImage);
        formData.append('removedMainImage', team.mainImage);
        count++;
      }
      if (description !== team.description) {
        formData.append('description', description);
        count++;
      }
      if (objectPositionValue !== team.objectPositionValue) {
        formData.append('objectPositionValue', objectPositionValue);
        count++;
      }
      if (count < 1) {
        console.log('No changes detected');
        return;
      }
      try {
        await dispatch(editTeams(id, formData));
        navigate('/teams');
      } catch (error) {
        console.log('Error updating team', error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.memberName.trim()) {
      errors.memberName = 'Member name is required';
    }
    if (!data.mainImage) {
      errors.mainImage = 'Image is required';
    }
    return errors;
  };

  const handleChangeFile = (e) => {
    setMainImage(e.target.files[0]);
    setMainImageURL(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <BreadCrumbs title={'Edit Team Member'} sx={{ mb: 2 }} />
        <Grid item xs={12} md={12}>
          <Grid container>
            <Grid item xs={12} md={3.5}></Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Member Name"
                      variant="outlined"
                      value={memberName}
                      onChange={(e) => {
                        setErrors({ ...errors, memberName: '' });
                        setMemberName(e.target.value);
                      }}
                      error={Boolean(errors.memberName)}
                      helperText={errors.memberName}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Designation"
                      variant="outlined"
                      value={memberDesignation}
                      onChange={(e) => {
                        setErrors({ ...errors, memberDesignation: '' });
                        setMemberDesignation(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  {mainImageURL ? (
                    <Box sx={{ height: '75px', width: '75px' }}>
                      <Avatar variant="square" sx={{ width: '100%', height: 75 }}>
                        <img style={{ height: 75 }} src={mainImageURL} alt="member" />
                      </Avatar>
                    </Box>
                  ) : (
                    ''
                  )}

                  <Box mb={2}>
                    <Typography>Image</Typography>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<Upload />}
                      fullWidth
                      style={{ marginBottom: '10px' }}
                    >
                      Upload Image
                      <input type="file" hidden onChange={handleChangeFile} />
                    </Button>
                    <Typography variant="caption" color="error">
                      {errors.mainImage}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <Typography>Description</Typography>
                    <TextareaAutosize
                      minRows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ width: '100%', padding: '10px', backgroundColor: '#fff' }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Object Position Value"
                      type="number"
                      value={objectPositionValue}
                      onChange={(e) => setObjectPositionValue(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button variant="contained" color="primary" onClick={handleClickUpdate} fullWidth>
                    Update Team Member
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3.5}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditTeams;
