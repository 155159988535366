import { GET_TEAMS } from './constants';

const initialState = {
  teamsList: null
};

export default function teams(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        teamsList: payload.arrList
      };

    default:
      return state;
  }
}
